<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
        >
          <b-card-body>
            <b-row>
              <b-col
                cols="6"
                xl="6"
                md="6"
              >
                <b-button
                  variant="primary"
                  @click="downloadUserTemplate()"
                >
                  <span> {{ $t('DOWNLOAD_USER_TEMPLATE') }}  </span>
                  <feather-icon icon="DownloadIcon" />
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
        >
          <b-card-body>
            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <!-- Company -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('COMPANY')"
                      label-for="id-company"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('COMPANY')"
                        rules="required"
                      >
                        <v-select
                          id="id-company"
                          v-model="company"
                          :placeholder="$t('SELECT_COMPANY')"
                          label="companyname"
                          class="flex-grow-1"
                          :clearable="false"
                          :options="companies"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- File -->
                  <b-col md="6">
                    <b-form-group
                      :label="$t('SELECT_FILE')+ ' (*.xlsx)'"
                      label-for="id-file"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('SELECT_FILE')+ ' (*.xlsx)'"
                        rules="required"
                      >
                        <b-form-file
                          v-model="selectedFile"
                          :placeholder="$t('CHOOSE_FILE_OR_DROP')"
                          drop-placeholder="Drop file here..."
                          accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- submit and reset -->
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ $t('BUTTON.SUBMIT') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                    >
                      {{ $t('BUTTON.RESET') }}
                    </b-button>
                  </b-col>

                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        xl="12"
        md="12"
      />
    </b-row>
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody,
  BButton, BForm, BFormGroup,
  BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
// eslint-disable-next-line import/no-extraneous-dependencies
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormFile,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      selectedFile: null,
      required,
      company: '',
      companies: [],
    }
  },
  async created() {
    await this.fetchCompany()
  },
  setup() {
    const {
      successMessage, errorMessage, showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      errorMessage,
      showErrorMessage,
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters('company', ['getAllCompanies']),
  },
  methods: {
    ...mapActions('company', ['fetchAllCompanies']),
    ...mapActions('user', ['uploadUsers']),
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          if (this.selectedFile) {
            const payload = { companyID: this.company.companyID, file: this.selectedFile }
            this.uploadUsers(payload).then(response => {
              if (response) {
                this.selectedFile = null
                this.company = ''
                this.successMessage(this.$i18n.t('MESSAGE.UPLOAD_USER_SUCCESSFULLY'))
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        }
      })
    },
    downloadUserTemplate() {
      const fileUrl = `${process.env.VUE_APP_HOST}files/bisevo-user-template.xlsx`
      const link = document.createElement('a')
      link.href = fileUrl
      link.click()
    },
    async fetchCompany() {
      this.fetchAllCompanies().then(response => {
        if (response) {
          this.companies = this.getAllCompanies
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
